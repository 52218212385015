export const ALL_TRAFFIC = 'All Traffic';

export const ALL_SITE_LIST = {
  'Allure-US': 'allure',
  'Architectural Digest-France': 'ad',
  'Architectural Digest-Germany': 'ad',
  'Architectural Digest-India': 'ad',
  'Architectural Digest-Italy': 'ad',
  'Architectural Digest-Mexico': 'ad',
  'Architectural Digest-Middle East': 'ad',
  'Architectural Digest-Spain': 'ad',
  'Architectural Digest-US': 'ad',
  'Bon Appetit-US': 'ba',
  'CN Traveler-India': 'cnt',
  'CN Traveler-Middle East': 'cnt',
  'CN Traveler-Spain': 'cnt',
  'CN Traveler-UK': 'cnt',
  'CN Traveler-US': 'cnt',
  'Epicurious-US': 'epi',
  'Glamour-Germany': 'glamour',
  'Glamour-Mexico': 'glamour',
  'Glamour-Spain': 'glamour',
  'Glamour-UK': 'glamour',
  'Glamour-US': 'glamour',
  'GQ-France': 'gq',
  'GQ-Germany': 'gq',
  'GQ-India': 'gq',
  'GQ-Italy': 'gq',
  'GQ-Japan': 'gq',
  'GQ-Mexico': 'gq',
  'GQ-Spain': 'gq',
  'GQ-Taiwan': 'gq',
  'GQ-UK': 'gq',
  'GQ-US': 'gq',
  'House & Garden-UK': 'h&g',
  'LaCucina-Italy': 'lci',
  'Pitchfork-US': 'pitchfork',
  'Self-US': 'self',
  'Tatler-UK': 'tatler',
  'Teen Vogue-US': 'teenvogue',
  'The New Yorker-US': 'tny',
  'The World of Interiors-UK': 'woi',
  'Them-US': 'them',
  'Vanity Fair-France': 'vanityfair',
  'Vanity Fair-Italy': 'vanityfair',
  'Vanity Fair-Spain': 'vanityfair',
  'Vanity Fair-US': 'vanityfair',
  'Vogue Business-US': 'vanityfair',
  'Vogue-France': 'vogue',
  'Vogue-Germany': 'vogue',
  'Vogue-India': 'vogue',
  'Vogue-Italy': 'vogue',
  'Vogue-Japan': 'vogue',
  'Vogue-Mexico': 'vogue',
  'Vogue-Spain': 'vogue',
  'Vogue-Taiwan': 'vogue',
  'Vogue-UK': 'vogue',
  'Vogue-US': 'vogue',
  'Wired-Global': 'wired',
  'Wired-Italy': 'wired',
  'Wired-Japan': 'wired',
  'Wired-UK': 'wired',
  'Wired-US': 'wired',
};
export const VOGUE_US = 'Vogue-US';
export const GQ_US = 'GQ-US';

export const CACHED_S3_URL = 'https://beacon-cache-api-dash.gp-prod-na-0.conde.digital/read-json/';

export const TRAFFIC_COLOR_CODE_GROUPS = {
  googleDiscover : ['Google Discover', 'Est. Google Discover'],
  other : ['Other', 'Others'],
  email : ["Email",'Newsletter', 'Other Email'],
  organicSearch : ["Organic Search",'Google Search', 'Other Search'],
  social : ["Social",'Facebook', 'Twitter', 'Instagram', 'Pinterest', 'LinkedIn','Linkedin','Threads', 'Snapchat','Other Social','TikTok', 'Reddit', 'YouTube','Whatsapp'],
  referralPartners : ["Referral", 'Apple News', 'Bloomberg', 'Flipboard','Mcclatchy','Msn','Newsbreak','Opera','Pocket','Smart News','Yahoo','Upday','Feedly','Line','Viber','Drudgereport',
  'Substack','Inoreader','Glance','Refind','Mixerbox','Theskimm','Inshorts', 'Google News','Google news','Smartnews','Applenews','Ground.news','News360','Newsnow','Newzit','Themorningnews','Artifact','Themorningnews', 'Drudge Report', 'Hacker News'
 ],
  direct : ["Direct"],
  internal :  ["Internal"],
  display : ["Display"]
}

export const channelColorMap = (view) => ({
  Direct: '#32CD32',
  Email: '#228B22',
  'Google Discover': '#9370DB',
  'Search': '#87CEFA',
  Internal: '#D8F1D7',
  'Referral Partners': '#FFAFB4',
  Social: '#FF1493',
  Others: '#483D8B',
  Other: '#483D8B',
});

export const brandWithYearWiseSplit = ['vogue', 'gq', 'glamour', 'vanityfair'];